import P, { Span } from '@components/Components/Reusables/Typography/P'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Image from 'next/image'

const list = [
    'Premium quality exhibition furniture',
    'Online portal to track & manage orders',
    'Discounts for registered partners',
    'Free delivery direct to your stand',
    'Hassle-free collection after your show',    
    'Priority support',    
]
const EhHomeAboutUs = ({isMobile}) => {
    return (
        <Box>
            <Grid className="m-0" container columnSpacing={{xs: 0, lg: 4}}>
                <Grid item sm={12} lg={7} 
                    sx={{ 
                        position: 'relative', 
                        height: {
                            xs: '200px',
                            lg: '400px',
                        },
                        width: '100%'
                    }}
                >
                        
                    <Image 
                        style={{ objectFit: 'cover' }} 
                        src={`/images/${process.env.brand.acronym}/about-exhibit-hire.jpg`}
                        quality={75}
                        priority
                        layout="fill"
                        // sizes="1000px"
                        alt="about-exhibit-hire"
                    />
                </Grid>
                <Grid item sm={12} lg={5}>
                    <Typography 
                        className="mt-2 dark-grey uppercase" 
                        component="h2" 
                        as="h2" 
                        sx={{
                            fontSize: {
                                xs: 18,
                                lg: 30
                            }, 
                            fontWeight: 500
                        }}
                    >
                        About Exhibit Hire
                    </Typography><br/>

                    <P 
                        className="dark-grey" 
                        sx={{
                            fontSize: {
                                xs: 16,
                                lg: 18
                            }
                        }}
                    >
                        We provide furniture hire services to exhibition venues across the UK. Benefits of working with us include:
                    </P>
                    <ul className="mt-3">
                        {list.map((item) => (
                            <li className="d-flex mb-3" key={item}>
                            <Image
                                style={{
                                    objectFit: "cover",
                                    height: isMobile ? "1em" : "1.5em", 
                                    width:  isMobile ? "1em" : "1.5em", 
                                    verticalAlign: "middle"
                                }}
                                height={isMobile ? 15 : 25}
                                width={isMobile ? 15 : 25}
                                src={`/images/${process.env.brand.acronym}/eh-bulletpoint.png`}
                                quality={75}
                                priority
                                // sizes="(max-width: 768px) 5vw, 33vw"
                                alt="icon"
                            />
                            <Span 
                                className="ml-3 dark-grey" 
                                style={{ lineHeight: "1.3em" }}
                                sx={{
                                    fontSize: {
                                        xs: 16,
                                        lg: 18,
                                    }
                                }}
                            >
                                {item}
                            </Span>
                            </li>
                        ))}
                    </ul>

                </Grid>
            </Grid>
        </Box>
    )
}

export default EhHomeAboutUs